import { CircularProgress } from "@material-ui/core";
import { styled } from "../../theme";

export const TimerContainer = styled.div`
  background-image: linear-gradient(to top, #8dc63f 0%, #fdee22 100%);
  display: flex;
  border-radius: 100%;
  position: relative;
`;

export const TopCircularProgress = styled(CircularProgress)`
  &.mobile-circular-progress {
    color: #aed0f0;
  }
`;

export const LabelContainer = styled.div`
  background-color: #80bef1;
  border-radius: 100%;
  position: absolute;
  width: 85%;
  height: 85%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.p`
  color: ${props => props.theme.color.text};
  font-family: ${props => props.theme.font.mainFont};
  font-weight: bold;
`;
