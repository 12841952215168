import React from "react";
import {
  SpeechBubbleMainContainer,
  SpeechBubbleArrow,
  SpeechArrowRowContainer,
  PapagayoContainer,
  MainBubbleContainer,
} from "./BirdSpeech.style";
import Papagayo from "../Papagayo/Papagayo.component";

interface Props {
  stopPapagayo?: boolean;
  customClass?: string;
}

const CustomBirdSpeech: React.FC<Props> = props => {
  return (
    <SpeechBubbleMainContainer className={`birdspeech-container ${props.customClass ? props.customClass : ""}`}>
      <MainBubbleContainer className={"main-bubble-container"}>{props.children}</MainBubbleContainer>
      <SpeechArrowRowContainer className={"bubble-arrow-row"}>
        <SpeechBubbleArrow className={"bubble-arrow"} />
      </SpeechArrowRowContainer>
      <PapagayoContainer className={"bird-head-container"}>
        <Papagayo stopPapagayo={!!props.stopPapagayo} />
      </PapagayoContainer>
    </SpeechBubbleMainContainer>
  );
};

export default CustomBirdSpeech;
