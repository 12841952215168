import styled, { keyframes } from "styled-components";
import { theme } from "../../theme";

export const Focus = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`;

interface AnswerProps {
  correct?: boolean;
  isThisaChoosenAnswer?: boolean;
}

export const AnswerContainer = styled.div<AnswerProps>`
  margin-top: 1vh;
  border-radius: 2.5rem;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  min-width: 20vw;
  width: 45%;
  max-width: 60%;
  min-height: 4em;
  height: 4em;
  background-color: #ffffff;
  border: ${props => (props.isThisaChoosenAnswer ? "solid 0.1em cyan" : "none")};
  font-size: 1.2em;
  font-weight: bolder;
  box-shadow: 2px 2px 4px #00000061;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    min-width: 80%;
  }
`;

export const IconContainer = styled.div`
  width: 2em;
  height: 2em;
  padding: 0.25em;
`;

export const QuestionTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
`;
