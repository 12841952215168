import React, { useContext } from "react";
import { PlayerHudComponentContainer, ViewerContainer } from "../PlayerHud.style";
import { CharacterEditorViewer } from "../../../Ducks/Avatar/components/CharacterViewer/CharacterViewer.component";
import LogicContext from "../../../context/context";

const PlayerRoundHud: React.FC = () => {
  const { userState, applicationState } = useContext(LogicContext);

  if (userState.store.me !== undefined) {
    return (
      <PlayerHudComponentContainer className={"player-hud-component-container"}>
        <ViewerContainer>
          {userState.store.me && typeof userState.store.me?.avatar !== "string" && (
            <CharacterEditorViewer
              heigthProps={"10em"}
              type="avatar"
              urlParts={applicationState.store.avatarPartsList?.url + "/avatar_parts/"}
              clothesIndex={userState.store.me?.avatar?.bodyIndex || 0}
              earsIndex={userState.store.me?.avatar?.earsIndex || 0}
              eyesIndex={userState.store.me?.avatar?.eyesIndex || 0}
              hairIndex={userState.store.me?.avatar?.hairIndex || 0}
              mouthIndex={userState.store.me?.avatar?.mouthIndex || 0}
              noseIndex={userState.store.me?.avatar?.noseIndex || 0}
              pencilIndex={userState.store.me?.avatar?.pencilIndex || 0}
              shapeIndex={userState.store.me?.avatar?.shapeIndex || 0}
            />
          )}
          {typeof userState.store.me?.avatar === "string" && (
            <CharacterEditorViewer heigthProps={"10em"} type={"star"} avastarUrl={userState.store.me?.avatar} />
          )}
        </ViewerContainer>
      </PlayerHudComponentContainer>
    );
  }

  return <div></div>;
};

export default PlayerRoundHud;
