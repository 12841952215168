import styled from "styled-components";

export const Icon = styled.div<{ url: string }>`
  background: ${({ url }) => `url(${url})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  border: none;
  pointer-events: none;
`;

export const Button = styled.button<{ widthInPx: number }>`
  background-image: linear-gradient(to top, #2c92f1, #90dff5);
  align-items: center;
  user-select: none;
  padding: 0.6em;
  border: none;
  user-select: none;
  cursor: pointer;
  box-shadow: 0px 1px 4px -2px #333;
  outline: none;
  width: ${({ widthInPx }) => `${widthInPx}px`};
  height: ${({ widthInPx }) => `${widthInPx}px`};
  border-radius: 100%;
`;
