import React from "react";
import { LockDiv } from "./Lock.style";

interface Props {
  imageUrl: string;
}

const Lock: React.FC<Props> = props => {
  return <LockDiv className={"lock-div"} lockImage={props.imageUrl}></LockDiv>;
};

export default Lock;
