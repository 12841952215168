import React, { HTMLAttributes } from "react";
import { Button, Icon } from "./CircledButton.style";

interface Props {
  widthInPx?: number;
  iconUrl: string;
}

const CircledButton = (props: Props & HTMLAttributes<HTMLButtonElement>) => {
  const { widthInPx, iconUrl, ...buttonProps } = props;

  return (
    <Button widthInPx={widthInPx || 40} {...buttonProps}>
      <Icon url={iconUrl} />
    </Button>
  );
};

export default CircledButton;
