import React from "react";
import { AbandonpButtonDiv } from "./AbandonButton.style";

interface Props {
  onClick?: () => void;
  buttonText: string;
}

const HelpButton: React.FC<Props> = props => {
  const doAction = (event: React.MouseEvent) => {
    event.preventDefault();
    if (props.onClick) props.onClick();
  };
  return (
    <AbandonpButtonDiv className={"abandon-button-div"} onClick={doAction} value={props.buttonText}>
      {props.buttonText}
    </AbandonpButtonDiv>
  );
};

export default HelpButton;
