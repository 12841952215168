import Store from "./application.store";
import { action } from "mobx";
import { AvatarAvailableParts, Avatar } from "../../Avatar/models/avatar.model";
import { ApplicationConfig } from "../models/application.model";

export interface ApplicationrMutatorInterface {
  setAssetsPartsList: (avatarPartsList: AvatarAvailableParts) => void;
  saveAvatar: (savedAvatar: Avatar) => void;
  setConfig: (config: ApplicationConfig) => void;
  setAvailableAvastarList: (list: string[]) => void;
  setBrowserLanguage: (browserLanguage: string) => void;
}

export default class Mutator implements ApplicationrMutatorInterface {
  private readonly store: Store;

  constructor(props: { store: Store }) {
    this.store = props.store;
  }

  @action
  saveAvatar(savedAvatar: Avatar) {
    this.store.savedAvatar = savedAvatar;
  }
  @action
  setAssetsPartsList(avatarPartsList: AvatarAvailableParts) {
    this.store.avatarPartsList = avatarPartsList;
  }

  @action
  setConfig(config: ApplicationConfig) {
    this.store.config = config;
  }

  @action
  setBrowserLanguage(browserLanguage: string) {
    this.store.browserLanguage = browserLanguage;
  }

  @action
  setLanguageList(languageList: string[]) {
    this.store.languageList = languageList;
  }

  @action
  setDefaultLanguage(lang: string) {
    this.store.defaultLanguage = lang;
  }

  @action
  setAvailableAvastarList(list: string[]) {
    this.store.avastarList = list;
  }

  @action
  mute() {
    this.store.isMuted = true;
  }

  @action
  unmute() {
    this.store.isMuted = false;
  }
}
