import i18n from "i18next";
import { err } from "neverthrow";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resources from "./locales";
import ApplicationStateInterface from "../Ducks/application/state";
import { STORAGE_KEYS } from "../services/storage.service";

export const SUPPORTED_LANGUAGE = ["fr", "en"];
export const DEFAULT_LANGUAGE = "fr";

export const getBrowserLanguageFromil8nIndex = () => {
  try {
    const i18nObject = i18n;
    return i18nObject.language;
  } catch (e) {
    return err(`${e.message}`);
  }
};

export const getCurrentLang = () => {
  return i18n.language;
};

export const changeLang = (lang: string) => {
  document.documentElement.setAttribute("lang", lang);
  i18n.changeLanguage(lang);
};

export const getDefaultLanguage = () => {
  return ApplicationStateInterface.store.defaultLanguage;
};

export const getSupportedLanguage = () => {
  if (
    ApplicationStateInterface.store.languageList !== undefined &&
    ApplicationStateInterface.store.languageList.length > 0
  ) {
    return ApplicationStateInterface.store.languageList;
  } else {
    return SUPPORTED_LANGUAGE;
  }
};

export const translateContent = (array: { lang: string; value: string }[]) => {
  const result = array.find(a => a.lang === getCurrentLang());
  if (result !== undefined) {
    return result.value;
  } else {
    return "";
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ["localStorage"], lookupLocalStorage: STORAGE_KEYS.CURRENT_LANG },
    resources: resources,
    fallbackLng: false,
    load: "languageOnly",
    cleanCode: true,
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
