import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import SnackbarContent from "@material-ui/core/SnackbarContent";

export interface ShowToastProps {
  type: "success" | "error" | "info" | "warning";
  message: string;
}

const mappedColors: { [key: string]: string } = {
  warning: "orange",
  error: "red",
  info: "blue",
  success: "green",
};

export interface WithSnackProps {
  showToast: (props: ShowToastProps) => void;
}

const WhithSnack = <P,>(WrappedComponent: React.ComponentType<P>): React.FC<Omit<P, keyof WithSnackProps>> =>
  function WhithSnackFun({ ...props }) {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [toast, setToast] = useState<ShowToastProps>({ message: "", type: "info" });

    const hideToast = () => {
      setOpen(false);
      setToast(toast);
    };

    const useStyles = makeStyles((theme: Theme) => ({
      root: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "row",
        "& > * + *": {},
      },
    }));

    const classes = useStyles();

    const showToast = (props: ShowToastProps) => {
      setOpen(true);
      setToast(props);
    };
    return (
      <React.Fragment>
        <Snackbar
          className={classes.root}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isOpen}
          onClose={hideToast}
        >
          <SnackbarContent
            style={{
              backgroundColor: mappedColors[`${toast.type}`] || "red",
              fontWeight: "bold",
            }}
            message={toast.message.toString()}
          />
        </Snackbar>

        <WrappedComponent showToast={showToast} {...(props as P)} />
      </React.Fragment>
    );
  };

export default WhithSnack;
