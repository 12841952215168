import React from "react";
import {
  BonusButtonsContainer,
  FiftyButtonContainer,
  JokerButtonAssembly,
  RightBonusRow,
  LeftBonusRow,
} from "./QuestionBonusButtonsModule.style";
import { JokerButton, FifthyFifthyButton } from "../../";

interface Props {
  jokerAmount: number;
  openModal: (type: "joker" | "fifthy") => void;
}

const QuestionBonusButtonsModule: React.FC<Props> = props => {
  return (
    <BonusButtonsContainer>
      <LeftBonusRow>
        {props.jokerAmount !== undefined && props.jokerAmount > 0 ? (
          <JokerButtonAssembly className={"joker-button-assembly"}>
            <JokerButton count={props.jokerAmount} useJoker={() => props.openModal("joker")} />
          </JokerButtonAssembly>
        ) : null}
      </LeftBonusRow>
      <RightBonusRow>
        <FiftyButtonContainer>
          <FifthyFifthyButton useFifthyFifthy={() => props.openModal("fifthy")} />
        </FiftyButtonContainer>
      </RightBonusRow>
    </BonusButtonsContainer>
  );
};

export default QuestionBonusButtonsModule;
