import { styled, theme } from "../../theme";

export const QuestionTitleStyleFlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 1rem;
  font-size: 1.7rem;
  font-weight: bold;
  color: ${({ theme }) => theme.color.text};
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    display: none;
  }
`;

export const QuestionTextStyleFlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 2rem;
  padding: 1.5rem;
  font-size: 2.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.color.text};
  text-align: center;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 1.5em;
    padding: 10px 0;
  }
`;

export const AdviceStyleFlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 1vh;
  padding-bottom: 5vh;
  min-height: 2rem;
  font-size: 0.8rem;
  font-weight: normal;
  color: white;
`;

export const SeparatorHorizontalStyleDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 0.1em solid white;
  max-width: 5%;
  margin: auto;
  align-items: center;
  justify-content: center;
  padding-bottom: 2vh;
`;
