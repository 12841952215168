import React, { useState } from "react";
import {
  SpeechBubbleRowContainer,
  SpeechBubbleMainContainer,
  SpeechBubbleArrow,
  SpeechArrowRowContainer,
  PapagayoMessage,
  PapagayoContainer,
  MainBubbleContainer,
  TreasureAndLockRowFlexContainer,
  QuestionBolderText,
  DebriefTitlediv,
  GoFinalRequestDiv,
} from "./FinalBirdSpeech.style";
import Papagayo from "../Papagayo/Papagayo.component";
import Typewriter from "../../styles/FX/Typewriter/Typewriter";
import { Round } from "../../Ducks/game/models/game.model";
import { ProgressLockBar } from "../../DumbComponents";
import { Question } from "../../Ducks/game/models/game.model";
import { useTranslation } from "react-i18next";

interface Props {
  roundList: Round[];
  adviceText: string;
  triggerTypeWriter: boolean;
  openLockImage: string;
  closedLockImage: string;
  jackpotValue: number;
  title: string | JSX.Element;
  questionText: string;
  questionIndex: number;
  roundIndex: number;
  questionList: Question[];
}

const FinalBirdSpeech: React.FC<Props> = props => {
  const [stopPapagayo, powerOffPapagayo] = useState<boolean>(false);
  const { t } = useTranslation("final");
  const stopAnimation = (command: boolean) => {
    powerOffPapagayo(command);
  };

  return (
    <React.Fragment>
      <GoFinalRequestDiv>{t("finalRoundInterludes.goFinalRequest")}</GoFinalRequestDiv>
      <SpeechBubbleMainContainer className={"speech-bubble-main-container"}>
        <MainBubbleContainer>
          <SpeechBubbleRowContainer>
            <DebriefTitlediv>{props.title}</DebriefTitlediv>
          </SpeechBubbleRowContainer>
          <PapagayoMessage className={"papayo-message"}>
            <Typewriter stopAnimation={stopAnimation} sourceText={props.adviceText} textClass="light medium" />
          </PapagayoMessage>
          <TreasureAndLockRowFlexContainer>
            <ProgressLockBar
              questionList={props.questionList}
              roundList={props.roundList}
              roundIndex={props.roundIndex}
              closedLockImage={props.closedLockImage}
              openLockImage={props.openLockImage}
              questionIndex={props.questionIndex}
            />
          </TreasureAndLockRowFlexContainer>
          <QuestionBolderText>{props.questionText}</QuestionBolderText>
        </MainBubbleContainer>
        <SpeechArrowRowContainer className={"speech-arrow-row-container"}>
          <SpeechBubbleArrow />
        </SpeechArrowRowContainer>
        <PapagayoContainer className={"papagayo-container"}>
          <Papagayo stopPapagayo={stopPapagayo} />
        </PapagayoContainer>
      </SpeechBubbleMainContainer>
    </React.Fragment>
  );
};

export default FinalBirdSpeech;
