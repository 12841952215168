/* eslint-disable react/display-name */
import React, { useState } from "react";
import { Spinner } from "..";
import { LoaderLayout } from "./withLoader.style";

export interface WithLoaderProps {
  displayLoader: (display: boolean) => void;
}

const withLoader = <P,>(WrappedComponent: React.ComponentType<P>): React.FC<Omit<P, keyof WithLoaderProps>> => ({
  ...props
}) => {
  const [isDisplayingLoader, displayLoader] = useState(false);

  return (
    <React.Fragment>
      {isDisplayingLoader && (
        <LoaderLayout>
          <Spinner />
        </LoaderLayout>
      )}
      <WrappedComponent displayLoader={displayLoader} {...(props as P)} />
    </React.Fragment>
  );
};

export default withLoader;
