import { styled, theme } from "../../theme";

export const FooterButtonDiv = styled.button`
  font-family: amiko;
  font-size: 0.8em;
  background: none;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${props => `${props.theme.color.text}`};
  height: 100%;
  min-height: 100%;
  user-select: none;
  border: none;
  cursor: pointer;
  font-weight: bolder;
  :focus {
    outline: none;
  }

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 0.5em;
  }
`;

export const FooterUrlLinkDiv = styled.a`
  font-family: amiko;
  font-size: 0.8em;
  background: none;
  color: ${props => `${props.theme.color.text}`};
  user-select: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-weight: bolder;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 0.5em;
  }
`;
