import styled from "styled-components";
import { theme } from "../../../theme";

interface StandardButtonInterface {
  secondaryColor: string;
}

export const StandardButtonStyle = styled.button<StandardButtonInterface>`
  user-select: none;
  z-index: 12000;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 2em;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0.25em 1em;
  color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: none;
  white-space: nowrap;
  background: #ff3700;
  max-height: 10%;
  overflow: hidden;
  position: relative;
  user-select: none;
  cursor: pointer;
  box-shadow: 0px 1px 4px -2px #333;
  transition: transform 250ms cubic-bezier(0.25, 0.25, 0.5, 1.9);
  outline: none;
  &:active {
    animation-fill-mode: forwards;
    transform: scale(1.1);
    outline: none;
    outline: 0;
    user-select: none;
  }
  &:focus {
    outline: 0;
    outline: none;
  }
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    border-radius: 2em;
    min-width: 50%;
    font-size: 1em;
    outline: none;
    padding: 1em;
  }
`;
