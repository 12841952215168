import GameStore from "./game.store";
import { Game, AnswerResult, FiftyResult, GameScreen } from "../models/game.model";

import { action } from "mobx";

export interface GameMutatorInterface {
  setRoundNumber: (roundNumber: number) => void;
  clearCurrentGameAnswerResultList: () => void;
  addResultToCurrentGame: (answer: AnswerResult | FiftyResult) => void;
  setGame: (gameSession: Game | undefined) => void;
  setCurrentGameScreen: (screen: GameScreen) => void;
}

export default class Mutator implements GameMutatorInterface {
  private readonly gameStore: GameStore;

  constructor(props: { gameStore: GameStore }) {
    this.gameStore = props.gameStore;
  }
  
  @action
  setRoundNumber(roundNumber: number) {
    this.gameStore.roundIndex = roundNumber;
  }

  @action
  setQuestionIndex(questionIndex: number) {
    this.gameStore.questionIndex = questionIndex;
  }

  @action
  setJokerAmount(jokerAmount: number) {
    this.gameStore.jokerAmount = jokerAmount;
  }

  @action
  setGame(gameSession: Game | undefined) {
    this.gameStore.game = gameSession;
  }

  @action
  setChoosenAnswerByPlayer(choosenAnswer: string) {
    this.gameStore.idOfLastAnswerChoosen = choosenAnswer;
  }

  @action
  clearCurrentGameAnswerResultList() {
    this.gameStore.currentGameAnswerResultList = [];
  }

  @action
  addResultToCurrentGame(answer: AnswerResult | FiftyResult) {
    this.gameStore.currentGameAnswerResultList.push(answer);
  }

  @action
  setCurrentGameScreen(screen: GameScreen) {
    this.gameStore.currentGameScreen = screen;
  }
}
