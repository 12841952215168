import styled from "styled-components";
import { theme } from "../../theme";

export const JokerButtonDiv = styled.button<{ width?: number }>`
  background-color: transparent;
  padding: 0;
  position: relative;
  z-index: 500000;
  left: 0;
  bottom: 0;
  height: ${({ width }) => (width ? `${width}px` : "100%")};
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  z-index: 0;
  outline: none;
  border: none;
  cursor: ${props => (props.onClick ? "pointer" : "default")};
`;

export const JokerCounter = styled.div`
  display: flex;
  font-weight: bolder;
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  background-color: white;
  color: black;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    width: 1.1rem;
    height: 1.1rem;
  }
`;

export const JokerImg = styled.img`
  width: 90%;
  height: 90%;
`;
