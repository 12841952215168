import React from "react";
import { LogoUpAndGoDiv } from "./LogoUpAndGo.style";

interface Props {
  imageUrl: string;
}
const LogoUpAndGo: React.FC<Props> = props => {
  return <LogoUpAndGoDiv href={"https://www.upandgo.fr"} target="__blank" imageUrl={props.imageUrl} />;
};

export default LogoUpAndGo;
