import styled, { css, keyframes } from "styled-components";
import { Rotation3dY } from "./Rotation3dY.atomic";

interface Image3DInterface {
  url: string;
  glowing?: boolean;
  withAnimation?: boolean;
}

export const Image3DContainer = styled.div`
  width: 5em;
  height: 5em;
`;

export const Focus = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
  }
`;
//${Rotation3dY}
export const Image3D = styled.div<Image3DInterface>`
  background-image: ${props => `url(${props.url})`};
  animation-name: ${props => (props.withAnimation ? Rotation3dY : "none")};
  animation-duration: 1s;
  animation-timing-function: ease-out 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform-origin: center;
  height: 100%;
  width: 100%;
  transition: transform 250ms cubic-bezier(0.25, 0.25, 0.5, 1.9);
  filter: ${props => (props.glowing ? "drop-shadow(0.1em 0.1em 0.2em rgba(255, 255, 255, 0.8))" : "")};

  ${props =>
    props.glowing &&
    css`
      outline: none;
      animation: ${Focus} 250ms 5;
      animation-fill-mode: both;
    `}
`;
