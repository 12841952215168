import { observer } from "mobx-react";
import React from "react";
import { computeAvatarUrlFromIndexes } from "../../../../utils";
import { MainCharacterContainer, CharacterBodyPartsContainer, AssetShapeImage } from "./CharacterViewer.style";

type StarProps = {
  type: "star";
  avastarUrl: string;
  heigthProps?: string;
  miniatureAvatar?: boolean;
};

type AvatarProps = {
  type: "avatar";
  pencilIndex: number;
  hairIndex: number;
  clothesIndex: number;
  eyesIndex: number;
  noseIndex: number;
  mouthIndex: number;
  earsIndex: number;
  shapeIndex: number;
  urlParts: string;
  heigthProps?: string;
  miniatureAvatar?: boolean;
};

const AvatarViewer: React.FC<AvatarProps> = observer(props => {
  return (
    <MainCharacterContainer
      className="character-container custom-avatar"
      miniatureAvatar={props.miniatureAvatar}
      heigthProps={props.heigthProps}
    >
      <CharacterBodyPartsContainer>
        <AssetShapeImage
          alt=""
          draggable="false"
          src={computeAvatarUrlFromIndexes(props.urlParts, "s", props.shapeIndex)}
        />
      </CharacterBodyPartsContainer>
      <CharacterBodyPartsContainer style={{ zIndex: 3 }}>
        <AssetShapeImage
          alt=""
          draggable="false"
          src={computeAvatarUrlFromIndexes(props.urlParts, "e", props.eyesIndex, props.pencilIndex)}
        />
      </CharacterBodyPartsContainer>
      <CharacterBodyPartsContainer style={{ zIndex: 4 }}>
        <AssetShapeImage
          alt=""
          draggable="false"
          src={computeAvatarUrlFromIndexes(props.urlParts, "n", props.noseIndex)}
        />
      </CharacterBodyPartsContainer>
      <CharacterBodyPartsContainer style={{ zIndex: 5 }}>
        <AssetShapeImage
          alt=""
          draggable="false"
          src={computeAvatarUrlFromIndexes(props.urlParts, "h", props.hairIndex, props.pencilIndex)}
        />
      </CharacterBodyPartsContainer>
      <CharacterBodyPartsContainer style={{ zIndex: 6 }}>
        <AssetShapeImage
          alt=""
          draggable="false"
          src={computeAvatarUrlFromIndexes(props.urlParts, "b", props.clothesIndex)}
        />
      </CharacterBodyPartsContainer>
      <CharacterBodyPartsContainer style={{ zIndex: 7 }}>
        <AssetShapeImage
          alt=""
          draggable="false"
          src={computeAvatarUrlFromIndexes(props.urlParts, "m", props.mouthIndex, props.pencilIndex)}
        />
      </CharacterBodyPartsContainer>
      <CharacterBodyPartsContainer style={{ zIndex: 8 }}>
        <AssetShapeImage
          alt=""
          draggable="false"
          src={computeAvatarUrlFromIndexes(props.urlParts, "o", props.shapeIndex)}
        />
      </CharacterBodyPartsContainer>
    </MainCharacterContainer>
  );
});

const StarViewer: React.FC<StarProps> = observer(props => {
  return (
    <MainCharacterContainer
      className="character-container avastar"
      miniatureAvatar={props.miniatureAvatar}
      heigthProps={props.heigthProps}
    >
      <AssetShapeImage className="avastar-picture" alt="" draggable="false" src={props.avastarUrl} />
    </MainCharacterContainer>
  );
});

export const CharacterEditorViewer = observer((props: AvatarProps | StarProps) => {
  if (props.type === "avatar") return <AvatarViewer {...props} />;
  else return <StarViewer {...props} />;
});
