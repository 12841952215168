import { styled } from "../../theme";

export const TimerFrameContainer = styled.div`
  min-width: 80%;
  border: solid white 0.1em;
  border-radius: 1em;
  height: 1.5em;
  overflow: hidden;
  background-color: #f4f4f47d;
  position: relative;
  max-width: 100%;
`;

export const TimerText = styled.p`
  color: ${({ theme }) => theme.color.text};
  font-size: 1.1em;
`;
