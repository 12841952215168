import { Game } from "../models/game.model";
import httpService from "../../../services/http.service";

export interface GameServicesInterface {
  getJackpot: () => Promise<number>;
  useJoker: (
    idGame: string,
    idQuestion: string,
    roundIndex: number,
  ) => Promise<{ points: number; success: boolean; validAnswerId: string }>;
  startGame: (browserLanguage: string) => Promise<Game>;
  answerQuestion: (props: {
    idGame: string;
    idQuestion: string;
    roundIndex: number;
    idAnswer: string;
  }) => Promise<{ points: number; success: boolean; validAnswerId: string }>;
  useFifty: (idGame: string, idQuestion: string, roundIndex: number) => Promise<{ answerList: string[] }>;
  abandonGame: (idGame: string) => Promise<any>;
  endGame: (idGame: string) => Promise<any>;
}

const getJackpot = async (): Promise<number> => {
  return 25900;
};

const useJoker = async (
  idGame: string,
  idQuestion: string,
  roundIndex: number,
): Promise<{ points: number; success: boolean; validAnswerId: string }> => {
  const useJokerResult = await httpService.post({
    auth: true,
    path: "/game/joker",
    body: {
      idGame: idGame,
      idQuestion: idQuestion,
      roundIndex: roundIndex,
    },
  });
  return useJokerResult.data;
};

const useFifty = async (idGame: string, idQuestion: string, roundIndex: number): Promise<{ answerList: string[] }> => {
  const result = await httpService.post({
    auth: true,
    path: "/game/fifty",
    body: {
      idGame,
      idQuestion,
      roundIndex,
    },
  });
  return result.data;
};

const answerQuestion = async ({
  idAnswer,
  idGame,
  idQuestion,
  roundIndex,
}: {
  idGame: string;
  idQuestion: string;
  roundIndex: number;
  idAnswer: string;
}): Promise<{ points: number; success: boolean; validAnswerId: string }> => {
  const result = await httpService.post({
    auth: true,
    path: "/game/answer",
    body: {
      idGame,
      roundIndex,
      idQuestion,
      idAnswer,
    },
  });
  return result.data;
};

const abandonGame = async (idGame: string) => {
  const result = await httpService.post({
    auth: true,
    path: "/game/abandon",
    body: {
      idGame,
    },
  });
  return result.data;
};

const endGame = async (idGame: string) => {
  const result = await httpService.post({
    auth: true,
    path: "/game/end",
    body: {
      idGame,
    },
  });
  return result.data;
};

const startGame = async (browserLanguage: string): Promise<Game> => {
  const result = await httpService.post({
    auth: true,
    path: "/game",
    body: {},
    language: browserLanguage,
  });
  return result.data.game;
};

const service: GameServicesInterface = {
  getJackpot,
  startGame,
  answerQuestion,
  useJoker,
  useFifty,
  endGame,
  abandonGame,
};

export default service;
