import React from "react";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
//import { core, store } from "../../state";
import i18n, { changeLang } from "../../i18n";
import { FlagContainer, FlagSelect } from "./LanguageSelector.style";

type Props = {
  languageList: string[];
};

const LanguageSelector: React.FC<Props> = props => {
  const onSelectChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    changeLang(event.target.value as string);
  };

  return (
    <FormControl className="language-selector-form">
      <FlagSelect
        className="language-selector-select"
        labelId="language-selector"
        label="Language"
        defaultValue={i18n.language}
        onChange={onSelectChange}
      >
        {props.languageList.map(lang => (
          <MenuItem className="language-selector-item" key={lang} value={lang}>
            <FlagContainer
              className="language-selector-flag-container"
              bg={"/assets/flags/" + `${lang}` + ".svg"}
            ></FlagContainer>
          </MenuItem>
        ))}
      </FlagSelect>
    </FormControl>
  );
};

export default LanguageSelector;
