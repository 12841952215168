import styled from "styled-components";
import Select from "@material-ui/core/Select";

export const FlagContainer = styled.div<{ bg: string }>`
  ${({ bg }) => `background-image: url(${bg})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 2em;
  height: 2em;
`;
export const FlagSelect = styled(Select)`
  border-color: red;
  &::before,
  &::after {
    border-bottom: inherit !important;
  }
`;
