import styled from "styled-components";
import { theme } from "../../theme";

interface HeaderProps {
  centering: boolean;
}

export const LogoAndCustomerBrandingRow = styled.div<HeaderProps>`
  top: 0;
  max-width: 100%;
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    min-height: 3em;
    width: 50%;
    justify-content: center;
  }
`;

interface CustomerLogoProps {
  customerLogo: string;
}

export const CustomerLogoContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 152px;
  height: 87px;
  right: 0px;
  padding-right: 10em;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    position: relative;
    height: auto;
    width: 8em;
    padding-right: 0;
  }
`;

export const CustomerLogoDiv = styled.div<CustomerLogoProps>`
  background: ${props => `url(${props.customerLogo})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${theme.color.header};
  width: 100%;
  height: 100%;
  border-radius: 0 0 8px 8px;
`;

export const GameLogoContainer = styled.div<HeaderProps>`
  width: 10%;
  height: 100%;
  padding-left: 10vw;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    display: none;
  }
`;

interface GameLogo {
  gameLogoUrl: string;
}

export const GameLogoDiv = styled.div<GameLogo>`
  background: ${props => `url(${props.gameLogoUrl})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;
