import styled from "styled-components";
import { theme } from "../../theme";

export const AnswerTitleStyleFlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 1rem;
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-size: 1.7rem;
  font-weight: normal;
  font-weight: bold;
  color: white;
`;

export const AnswerTextStyleFlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  min-height: 2rem;
  padding: 1.5rem;
  box-sizing: border-box;
  font-size: 1.9rem;
  font-weight: bolder;
  color: white;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 1rem;
  }
`;

export const SeparatorHorizontalStyleDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 0.1em solid white;
  max-width: 5%;
  margin: auto;
  align-items: center;
  justify-content: center;
  padding-bottom: 2vh;
`;
