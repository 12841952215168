import React from "react";
import { useTranslation } from "react-i18next";
import { StandardText } from "..";
import { CardContainer, Col, StyledText } from "./GameDescription.style";

interface Props {
  description: string;
  bonusLabel: string;
  bonusValue: number;
  pictureUrl: string;
}

const GameDescription: React.FC<Props> = props => {
  const { t } = useTranslation("common");

  return (
    <CardContainer className="rounded game-description-wrapper">
      <Col className="game-description-container">
        <StandardText className="game-description normal secondary" text={props.description} />
      </Col>
      <Col className="flex-center flex-col super-bonus-card">
        <img style={{ height: window.innerWidth > 768 ? "auto" : "100px" }} src={props.pictureUrl} />
        <StyledText className={"bonus-title"} text={props.bonusLabel} />
        <StyledText
          className={"bonus-points"}
          text={`+ ${props.bonusValue} ${t("point", { count: props.bonusValue })}`}
        />
      </Col>
    </CardContainer>
  );
};

export default GameDescription;
