import React, { useState, useEffect } from "react";
import { useScrollBodyLock } from "../../DumbComponents/Modal/ScrollLimiter";
import { TimerContainer, TopCircularProgress, LabelContainer, Label } from "./MobileTimer.style";

interface Props {
  initialTimeInSec: number;
  start: number;
  giveTimeLeft?: (curenttime: number) => void;
  giveEndTime: (endTime: boolean) => void;
}

const MobileTimer: React.FC<Props> = props => {
  const [counter, setCounter] = useState<number>(Math.round(props.initialTimeInSec));
  const { unlock } = useScrollBodyLock();

  useEffect(() => {
    counter <= 0 && props.giveEndTime(true);

    setTimeout(() => {
      if (counter > 0) {
        setCounter(counter - 1);
        return;
      } else {
        unlock();
        return;
      }
    }, 1000);
  }, [counter]);

  return (
    <TimerContainer className="mobile-circular-progress-container">
      <TopCircularProgress
        className="mobile-circular-progress"
        variant="determinate"
        value={Math.round(((props.initialTimeInSec - counter) * 100) / props.initialTimeInSec)}
      />
      <LabelContainer>
        <Label className="mobile-progress-label">{counter}</Label>
      </LabelContainer>
    </TimerContainer>
  );
};

export default MobileTimer;
