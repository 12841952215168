import styled from "styled-components";
import { theme } from "../../theme";

export const SpeechBubbleMainContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 0.5rem;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    align-self: center;
  }
`;

export const MainBubbleContainer = styled.div`
  padding: 2em;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-sizing: border-box;
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  z-index: 120000;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    padding: 1em;
    font-size: 1.2em;
  }
`;

export const SpeechBubbleRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const SpeechArrowRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const SpeechBubbleArrow = styled.div`
  width: 10%;
  left: 80%;
  max-height: 50%;
  border-style: solid;
  border-width: 0 6em 6em 0;
  margin-left: 10%;
  transform: rotateY(180deg);
  transform-origin: top;
  border-color: transparent #fff transparent transparent;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 0.8em;
    color: white;
  }
  width: 10%;
  border-width: 0 3em 3em 0;
`;

export const PapagayoContainer = styled.div`
  position: relative;
  width: 7rem;
  height: 7rem;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    width: 5rem;
    height: 5rem;
  }
`;

interface PapagayoMessageProps {
  gameScreenName: string;
}

export const PapagayoMessage = styled.div<PapagayoMessageProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: ${props => (props.gameScreenName === "roundResume" ? "2.5em" : "1.5em")};
  color: black;
  padding: 2em 1em;
  text-align: center;
  border-radius: 0.5em;
  font-size: 1em;
  font-weight: ${props => (props.gameScreenName === "roundResume" ? "bolder" : "normal")};
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 0.8em;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 4em;
  width: 100%;
  padding: 1em;
  justify-content: space-evenly;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    height: 2em;
    padding: 1em;
  }
`;

export const AdviceTextDiv = styled.div`
  font-size: 1em;
  font-weight: bolder;
  padding: 2vh;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: center;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 0.8em;
  }
`;

export const TitleDivStyle = styled.div`
  padding-top: 2vh;
  padding-bottom: 2vh;
  font-size: 1.1em;
  font-weight: bolder;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
`;
