import React, { useState, useEffect } from "react";
import { TimerFrameContainer, TimerText } from "./Timer.style";
import { useScrollBodyLock } from "../../DumbComponents/Modal/ScrollLimiter";

interface Props {
  Time: number;
  start: number;
  giveTimeLeft?: (curenttime: number) => void;
  giveEndTime: (endTime: boolean) => void;
}

const Timer: React.FC<Props> = props => {
  const [counter, setCounter] = useState<number>(props.Time);
  const { unlock } = useScrollBodyLock();

  const secondsToTime = (e: number) => {
    const h = Math.floor(e / 3600)
      .toString()
      .padStart(2, "0");
    const m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const s = Math.floor(e % 60)
      .toString()
      .padStart(2, "0");

    return h + ":" + m + ":" + s;
  };

  useEffect(() => {
    setTimeout(() => {
      if (counter > 0) {
        setCounter(counter - 1);
        return true;
      } else {
        unlock();
        return false;
      }
    }, 1000);
  }, [counter]);

  useEffect(() => {
    counter <= 0 && props.giveEndTime(true);
  });
  return (
    <>
      <TimerText className={"timer"}>{secondsToTime(counter)}</TimerText>
      <TimerFrameContainer className={"timer-fixed-bar"}>
        {/* If we use StyledComponents, layout is jumoing at each seconds. Why ? - ABEDOS 05/08/2021 */}
        <div
          className={"timer-decreasing-bar"}
          style={{
            color: "white",
            textAlign: "center",
            background: "linear-gradient(to left, #8dc63f 0%, #fdee22 100%)",
            borderRadius: "1em",
            height: "100%",
            width: `${(counter * 100) / props.Time}%`,
            transition: "width 1s",
          }}
        ></div>
      </TimerFrameContainer>
    </>
  );
};

export default Timer;
