import styled from "styled-components";
import { theme } from "../../theme";

interface TreasureProps {
  closedTreasureImage: string;
  openTreasureImage: string;
  win: boolean;
}

export const TreasureDiv = styled.div<TreasureProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
  background: ${props => (props.win ? `url(${props.openTreasureImage})` : `url(${props.closedTreasureImage})`)};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  position: relative;
  height: 100%;
  width: 100%;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    justify-content: center;
    font-size: 0.8em;
    margin: auto;
  }
`;

export const TreasureText = styled.div`
  font-size: 1rem;
  text-shadow: 0 0.1em 0.2em black;
  text-align: center;
  z-index: 5;
  padding-top: 2rem;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    padding-top: 2rem;
    margin: 0;
    text-align: center;
  }
`;

export const TreasureAnimationContainer = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1;
`;

export const TreasureCountValue = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: bolder;
  color: white;
  z-index: 5;
  stroke: 0.1em black;
  padding-top: 1rem;
  text-shadow: 0 0.1em 0.1em black;
  filter: drop-shadow(0 0 0.75rem black);
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 1.2rem;
    padding-top: 1vh;
    text-align: center;
  }
`;

export const TitleDivJackPot = styled.div`
  text-align: left;
  margin: auto;
  font-size: 1em;
  font-weight: bolder;
  filter: drop-shadow(0 0 0.75rem crimson);

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    text-align: center;
    font-size: 0.6em;
    margin: 0;
  }
`;
