import styled from "styled-components";

export const HelpButtonDiv = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  color: black;
  height: 5em;
  background: none;
  text-decoration: underline;
  font-weight: bolder;
  z-index: 999;
`;
