import styled, { keyframes } from "styled-components";
import { theme } from "../../theme";

export const Focus = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`;

export const AnswerStyle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.1em;
  font-weight: bolder;
  white-space: nowrap;
  height: 100%;
  justify-content: center;
`;

interface AnswerProps {
  correct?: boolean;
  isChoosen: boolean;
  disabled?: boolean;
}

export const AnswerContainer = styled.button<AnswerProps>`
  background-color: ${props => (props.disabled ? "#CFCFCF" : "#FFFFFF")};
  box-shadow: 0 0.5px 1.5px rgba(0, 0, 0, 0.12), 0 1.5px 3px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border: none;
  display: flex;
  flex-direction: row;
  font-size: 1.2em;
  align-items: center;
  margin-top: 1.2em;
  min-width: 20vw;
  padding: 2em 0.5em;
  height: 97px;
  position: relative;
  cursor: pointer;
  transition: transform 250ms cubic-bezier(0.25, 0.25, 0.5, 1.9);
  width: 48%;
  :focus {
    outline: none;
  }
  transition: transform 250ms cubic-bezier(0.25, 0.25, 0.5, 1.9);
  :hover {
    background-color: lightgray;
  }
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    margin-top: 0;
    margin-bottom: 15px;
    padding: 0 0.5em;
    width: 100%;
  }

  &.correct {
    border: solid 0.5em #e7f3e1;
    background-color: #e7f3e1;
  }

  &.wrong {
    background-color: #f7e1e2;
    border: solid 0.5em #f7e1e2;
  }

  &.selected {
    border: solid 0.5em #fff;
  }
`;

export const IconContainer = styled.div`
  display: flex;
`;

export const QuestionTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 1.2;

  &.answer-text-container {
    padding-left: 0.5em;
  }
`;
