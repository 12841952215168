import styled from "styled-components";

interface LogoDivProps {
  imageUrl: string;
}

export const LogoDiv = styled.div<LogoDivProps>`
  background: ${props => `url(${props.imageUrl})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;
