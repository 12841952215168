import React from "react";
import applicationCore from "../Ducks/application/core";
import applicationState from "../Ducks/application/state";
import authCore from "../Ducks/Auth/core";
import authState from "../Ducks/Auth/state";
import gameCore from "../Ducks/game/core";
import gameState from "../Ducks/game/state";
import userCore from "../Ducks/User/core";
import userState from "../Ducks/User/state";

const LogicContext = React.createContext<{
  applicationCore: typeof applicationCore;
  applicationState: typeof applicationState;
  authCore: typeof authCore;
  authState: typeof authState;
  gameCore: typeof gameCore;
  gameState: typeof gameState;
  userCore: typeof userCore;
  userState: typeof userState;
}>({
  applicationCore,
  applicationState,
  authCore,
  gameCore,
  gameState,
  userCore,
  userState,
  authState,
});

export default LogicContext;
