import React from "react";
import { PlayerHorizontalHud, PlayerHudRound } from "./PlayerHudVariants/";
import PlayerHud from "./PlayerHud.component";

interface Props {
  currentGameScreen?: string;
  updateAvatar?: () => void;
}

export const PlayerHudSwitcher: React.FC<Props> = props => {

  if (props.currentGameScreen !== undefined) {
    if (["home-mobile", "round-intro-mobile"].includes(props.currentGameScreen))
      return <PlayerHorizontalHud leftData={"accumulatedScoreLabel"} rightData={"ranking"} />;
    if (props.currentGameScreen === "roundIntro") return <PlayerHudRound />;
    if (props.currentGameScreen === "roundResume" || props.currentGameScreen === "finalIntro")
      return <PlayerHorizontalHud leftData={"score"} rightData={"joker"} />;
    if (["win", "loose", "abandon"].includes(props.currentGameScreen))
      return <PlayerHorizontalHud leftData={"score"} rightData={"ranking"} />;
    if (["answeredQuestions", "finalAnsweredQuestions"].includes(props.currentGameScreen))
      return <PlayerHorizontalHud leftData={"earnings"} rightData={"score"} />;
  }

  return <PlayerHud updateAvatar={props.updateAvatar} />;
};

export default PlayerHudSwitcher;
