import React, { useState, useEffect } from "react";
import { StandardText } from "../../../DumbComponents";

interface Props {
  sourceText: string;
  stopAnimation?: (stopAnimation: boolean) => void;
  textClass?: string;
}

const Typewriter: React.FC<Props> = props => {
  const stringsToBePrinted = props.sourceText.split("///");

  const [autoText, setAutotext] = useState<{ [key: number]: string }>(
    stringsToBePrinted.reduce((acc, cur, index) => ({ ...acc, [index]: "" }), {}),
  );
  const [i, setI] = useState<number>(0);
  const [j, setJ] = useState<number>(0);
  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    if (i >= stringsToBePrinted.length) {
      props.stopAnimation && props.stopAnimation(true);

      return;
    }

    setTimeout(() => setCounter(counter + 1), 15);
    setAutotext({ ...autoText, [i]: autoText[i] + stringsToBePrinted[i].charAt(j) });

    const isCurrentStringOver = j === stringsToBePrinted[i].length - 1;
    setJ(isCurrentStringOver ? 0 : j + 1);
    isCurrentStringOver && setI(i + 1);
  }, [counter]);

  return (
    <React.Fragment>
      {Object.entries(autoText).map(([index, string]) => {
        return string && <StandardText key={index} className={`secondary ${props.textClass || ""}`} text={string} />;
      })}
    </React.Fragment>
  );
};
export default Typewriter;
