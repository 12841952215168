import "mobx-react-lite/batchingForReactDom";
import React, { Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Spinner } from "./DumbComponents";
import { HOME, LANDING, ADMIN_SUFFIX } from "./routing";
import LogicContext from "./context/context";
import applicationCore from "./Ducks/application/core";
import applicationState from "./Ducks/application/state";
import authCore from "./Ducks/Auth/core";
import authState from "./Ducks/Auth/state";
import gameCore from "./Ducks/game/core";
import gameState from "./Ducks/game/state";
import userCore from "./Ducks/User/core";
import userState from "./Ducks/User/state";
import { GlobalContainer } from "./containers/public/publicContainer.style";
import { preFlightCheck } from "utils";
import { CircularProgress } from "@material-ui/core";
import WhithSnack, { WithSnackProps } from "WithSnack/WithSnack";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

const publicContainer = React.lazy(() => import("./containers/public"));
const homeContainer = React.lazy(() => import("./containers/game"));
const adminContainer = React.lazy(() => import("./containers/admin"));

const App: React.FC<WithSnackProps> = props => {
  const { t } = useTranslation(["landing"]);

  const [isLoading, setIsLoading] = useState(false);
  const [isAppFunctionnal, setIsAppFunctionnal] = useState(true);

  const setApp = async () => {
    setIsLoading(true);

    try {
      (await applicationCore.setApplicationConfig()) &&
        (await applicationCore.setAssetsPartsList()) &&
        (await applicationCore.setAvailableAvastarList());
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsAppFunctionnal(preFlightCheck());
    setApp();
  }, []);

  useEffect(() => {
    applicationState.store.isGameOpened === false &&
      props.showToast({
        message: t("landing:notStarted", {
          startDate: applicationState.store.config?.branding?.startDate?.toLocaleDateString() || "",
        }),
        type: "warning",
      });
    applicationState.store.isGameClosed === true &&
      props.showToast({ message: t("landing:finished"), type: "warning" });
  }, [applicationState.store.isGameOpened, applicationState.store.isGameClosed]);

  if (!isAppFunctionnal) {
    return <p>Missing env variables. Please check your console and build config.</p>;
  }

  if (isLoading) {
    return (
      <GlobalContainer style={{ alignItems: "center", justifyContent: "center", height: "100vh" }}>
        <CircularProgress />
      </GlobalContainer>
    );
  }

  if (applicationState.store.config === undefined || authState.store.authService === undefined) {
    return <p>Error while loading application.</p>;
  }

  return (
    <LogicContext.Provider
      value={{ applicationCore, applicationState, authCore, gameCore, gameState, userCore, userState, authState }}
    >
      <div>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route path={HOME.path} component={homeContainer} />
            <Route path={ADMIN_SUFFIX} component={adminContainer} />
            <Route path={LANDING.path} component={publicContainer} />
            <Route path={"/"} exact>
              <Redirect to={LANDING.path} />
            </Route>
          </Switch>
        </Suspense>
      </div>
    </LogicContext.Provider>
  );
};

export default observer(WhithSnack(App));
