import styled from "styled-components";

interface Joker {
  glowing?: boolean;
}

export const JokerDiv = styled.div<Joker>`
  width: 100%;
  height: 100%;
`;
