import styled from "styled-components";
import { theme } from "../../theme";

export const LabelPlayerInfoStyle = styled.div`
  font-family: lilita;
  color: white;
  font-size: 1.5em;
  line-height: 1.2em;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 1em;
    padding-top: 1vh;
    padding-bottom: 1vh;
  }
`;
