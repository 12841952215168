import { err, ok } from "neverthrow";
import { ApplicationStateInterface } from "../../application/state";
import { GameServicesInterface } from "../services/game.service";
import { GameStateInterface } from "../state";
import { playSound } from "../../../services/sound.service";
import { getCurrentLang } from "../../../i18n";

interface Props {
  gameService: GameServicesInterface;
  gameState: GameStateInterface;
  applicationState: ApplicationStateInterface;
}

const Core = ({ gameService, gameState, applicationState }: Props) => {
  const hasGameBeenFinished = () => {
    if (!gameState.store.game) throw new Error("Not current in a game");
    const currentRound = gameState.store.game.roundList[gameState.store.roundIndex];

    return (
      gameState.store.roundIndex === gameState.store.game.roundList.length - 1 &&
      gameState.store.questionIndex === currentRound.questionList.length - 1
    );
  };

  const getCurrentGameScore = () => {
    if (!gameState.store.game) throw new Error("Not current in a game");
    const currentRound = gameState.store.game.roundList[gameState.store.roundIndex];
    const sumOfScoreInCurrentGame = gameState.store.currentGameAnswerResultList.reduce(
      (score, result) => (result.type === "answer" ? score + result.points : score),
      0,
    );
    // Round non final, le score est celui de la partie
    if (!currentRound.isFinal) return sumOfScoreInCurrentGame;
    const hasLost = gameState.store.currentGameAnswerResultList.some(
      result => result.type === "answer" && !result.success,
    );
    // Perdu au round final le score est de 0
    if (hasLost) return 0;

    // Win au round final le score est celui de la partie + celui de la cagnotte
    if (
      gameState.store.roundIndex === gameState.store.game.roundList.length - 1 &&
      gameState.store.questionIndex === currentRound.questionList.length - 1
    ) {
      const lastAnswer =
        gameState.store.currentGameAnswerResultList[gameState.store.currentGameAnswerResultList.length - 1];
      const hasWon = lastAnswer.type === "answer" && lastAnswer.success;
      if (hasWon && gameState.store.currentGameScreen === "win")
        return sumOfScoreInCurrentGame + (applicationState.store.config?.jackpotAmount || 0);
      if (hasWon && gameState.store.currentGameScreen !== "win") return sumOfScoreInCurrentGame;
    }

    // Abandon
    return sumOfScoreInCurrentGame;
  };

  const playSoundAccordingToCurrentScreen = () => {
    const lastAnswer =
      gameState.store.currentGameAnswerResultList[gameState.store.currentGameAnswerResultList.length - 1];
    const gameScreenName = gameState.store.currentGameScreen;
    if (gameState.store.game && gameScreenName !== undefined) {
      const currentRound = gameState.store.game.roundList[gameState.store.roundIndex];
      if (gameScreenName === "roundIntro") {
        if (!currentRound.isFinal) {
          playSound("roundIntro");
        }
      }
      if (gameScreenName === "answeredQuestions" || gameScreenName === "finalAnsweredQuestions") {
        if (lastAnswer !== undefined && lastAnswer.type === "answer" && lastAnswer.success) {
          playSound("goodAnswer");
        } else {
          playSound("wrongAnswer");
        }
      }
      if (gameScreenName === "finalChoice") {
        if (gameState.store.questionIndex < 1) {
          playSound("papagayo");
        }
        if (gameState.store.questionIndex > 0) {
          playSound("openLock");
        }
      }
      if (gameScreenName === "roundResume") {
        playSound("newJoker");
      }

      if (gameScreenName === "finalIntro") {
        playSound("success");
      }

      if (gameScreenName === "win") {
        playSound("superBonusWin");
      }
    }
  };

  const playerChooseAnswer = (choosenAnswer: string) => {
    gameState.mutator.setChoosenAnswerByPlayer(choosenAnswer);
  };

  const useJoker = async () => {
    try {
      if (!gameState.store.game) return err("No current game");
      const result = await gameService.useJoker(
        gameState.store.game._id,
        gameState.store.game.roundList[gameState.store.roundIndex].questionList[gameState.store.questionIndex]._id,
        gameState.store.roundIndex,
      );
      gameState.mutator.addResultToCurrentGame({
        type: "answer",
        points: result.points,
        success: result.success,
        questionId:
          gameState.store.game.roundList[gameState.store.roundIndex].questionList[gameState.store.questionIndex]._id,
        roundIndex: gameState.store.roundIndex,
        validAnswerId: result.validAnswerId,
      });
      gameState.mutator.setChoosenAnswerByPlayer(result.validAnswerId);
      gameState.mutator.setJokerAmount(gameState.store.jokerAmount - 1);
      return ok(result);
    } catch (e) {
      return err(e);
    }
  };

  const useFifty = async () => {
    try {
      if (gameState.store.game) {
        const result = await gameService.useFifty(
          gameState.store.game._id,
          gameState.store.game.roundList[gameState.store.roundIndex].questionList[gameState.store.questionIndex]._id,
          gameState.store.roundIndex,
        );
        gameState.mutator.addResultToCurrentGame({
          type: "fifty",
          roundIndex: gameState.store.roundIndex,
          questionId:
            gameState.store.game.roundList[gameState.store.roundIndex].questionList[gameState.store.questionIndex]._id,
          answerList: result.answerList,
        });
        return ok(result);
      }
      return err("No current game");
    } catch (e) {
      return err(`${e.message}`);
    }
  };

  const startGame = async () => {
    try {
      const gameInitObject = await gameService.startGame(getCurrentLang());
      gameState.mutator.setGame(gameInitObject);
      gameState.mutator.setJokerAmount(gameInitObject.roundList[0].bonus.joker.roundStartQuantity);
      playSoundAccordingToCurrentScreen();
      return ok(gameInitObject);
    } catch (e) {
      if (e.message.includes("403")) {
        return err(403);
      }
      return err(`${e.message}`);
    }
  };

  const answerQuestion = async (idAnswer: string) => {
    try {
      if (gameState.store.game) {
        const result = await gameService.answerQuestion({
          idGame: gameState.store.game._id,
          idQuestion:
            gameState.store.game.roundList[gameState.store.roundIndex].questionList[gameState.store.questionIndex]._id,
          roundIndex: gameState.store.roundIndex,
          idAnswer,
        });
        gameState.mutator.addResultToCurrentGame({
          type: "answer",
          questionId:
            gameState.store.game.roundList[gameState.store.roundIndex].questionList[gameState.store.questionIndex]._id,
          points: result.points,
          roundIndex: gameState.store.roundIndex,
          success: result.success,
          validAnswerId: result.validAnswerId,
        });
        playSoundAccordingToCurrentScreen();
        return ok(result);
      }
      return err("Not currently in a game");
    } catch (e) {
      return err(`${e.message}`);
    }
  };

  const nextScreen = () => {
    if (gameState.store.game) {
      const currentRound = gameState.store.game.roundList[gameState.store.roundIndex];
      const lastAnswerResult =
        gameState.store.currentGameAnswerResultList[gameState.store.currentGameAnswerResultList.length - 1];
      if (gameState.store.currentGameScreen === "roundIntro") {
        gameState.mutator.setCurrentGameScreen("questions");
      } else if (gameState.store.currentGameScreen === "questions") {
        gameState.mutator.setCurrentGameScreen("answeredQuestions");
      } else if (gameState.store.currentGameScreen === "answeredQuestions") {
        if (lastAnswerResult.type === "answer" && lastAnswerResult.success) {
          if (gameState.store.questionIndex < currentRound.questionList.length - 1) {
            gameState.mutator.setQuestionIndex(gameState.store.questionIndex + 1);
            gameState.mutator.setCurrentGameScreen("questions");
          } else {
            gameState.mutator.setRoundNumber(gameState.store.roundIndex + 1);
            gameState.mutator.setQuestionIndex(0);
            gameState.mutator.setJokerAmount(
              gameState.store.jokerAmount +
                gameState.store.game.roundList[gameState.store.roundIndex].bonus.joker.roundStartQuantity,
            );
            gameState.mutator.setCurrentGameScreen("roundResume");
          }
        } else {
          gameState.mutator.setCurrentGameScreen("loose");
        }
      } else if (gameState.store.currentGameScreen === "roundResume") {
        gameState.mutator.setCurrentGameScreen(currentRound.isFinal ? "finalIntro" : "roundIntro");
      } else if (gameState.store.currentGameScreen === "finalIntro") {
        gameState.mutator.setCurrentGameScreen("finalChoice");
      } else if (gameState.store.currentGameScreen === "finalChoice") {
        gameState.mutator.setCurrentGameScreen("finalQuestions");
      } else if (gameState.store.currentGameScreen === "finalQuestions") {
        gameState.mutator.setCurrentGameScreen("finalAnsweredQuestions");
      } else if (gameState.store.currentGameScreen === "finalAnsweredQuestions") {
        if (lastAnswerResult.type === "answer" && lastAnswerResult.success) {
          if (gameState.store.questionIndex < currentRound.questionList.length - 1) {
            gameState.mutator.setQuestionIndex(gameState.store.questionIndex + 1);

            gameState.mutator.setCurrentGameScreen("finalChoice");
          } else {
            gameState.mutator.setCurrentGameScreen("win");
          }
        } else {
          gameState.mutator.setCurrentGameScreen("loose");
        }
      }
      playSoundAccordingToCurrentScreen();
    } else {
      return err("Not currently in a game");
    }
  };

  const abandonGame = async () => {
    if (gameState.store.game) {
      await gameService.abandonGame(gameState.store.game._id);
      //gameState.mutator.setCurrentGameScreen("win");
      gameState.mutator.setCurrentGameScreen("abandon");
      return ok("");
    } else {
      return err("Not currently in a game");
    }
  };

  const endGame = async () => {
    if (gameState.store.game) {
      await gameService.endGame(gameState.store.game._id);
      return ok("");
    } else {
      return err("Not currently in a game");
    }
  };

  const resetGame = () => {
    gameState.mutator.setCurrentGameScreen("roundIntro");
    gameState.mutator.setGame(undefined);
    gameState.mutator.clearCurrentGameAnswerResultList();
    gameState.mutator.setRoundNumber(0);
    gameState.mutator.setQuestionIndex(0);
  };

  return {
    startGame,
    answerQuestion,
    resetGame,
    useJoker,
    nextScreen,
    useFifty,
    playerChooseAnswer,
    abandonGame,
    endGame,
    getCurrentGameScore,
    hasGameBeenFinished,
  };
};

export default Core;
