import styled from "styled-components";
import { theme } from "../../theme";

export const PlayerHudComponentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  min-height: 10em;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    flex-wrap: wrap;
  }
`;

export const PlayerNameColumn = styled.div`
  display: flex;
  width: 40%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  order: 1;
  font-family: lilita;
  font-size: 2em;
  color: white;
  z-index: 4000;
  box-sizing: border-box;
  padding: 1rem;
  padding-bottom: 2rem;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    order: 3;
    width: 100%;
    text-align: center;
    justify-content: center;
    font-size: 1em;
    padding-top: 1rem;
  }
`;

export const AvatarCentralColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  order: 2;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    order: 1;
    width: 50%;
  }
`;

export const ScoreAndRankingColumn = styled.div`
  width: 40%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  order: 3;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    order: 2;
    width: 50%;
    height: 50%;
    align-items: center;
  }
`;

export const StyloForUpdateContainer = styled.button`
  display: flex;
  justify-content: center;
  width: 3em;
  height: 3em;
  position: absolute;
  bottom: 0;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  z-index: 999;
  border: none;
  cursor: pointer;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    right: 10%;
  }
`;

export const VerticalSeparator = styled.div`
  min-height: 100%;
  width: 0.1em;
  min-width: 0.1em;
  max-width: 0.1em;
  background-color: white;
  display: block;
  margin-left: 1vw;
  margin-right: 1vw;
`;

export const ColumnPlayerStatsInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 1em;
`;

export const LeftColumnPlayerStatsInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  padding-right: 1vw;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    width: 20%;
  }
`;

export const RightColumnPlayerStatsInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  padding-left: 1vw;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    width: 20%;
  }
`;

export const PlayerTotalScoreFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50%;
  padding-bottom: 1vh;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    padding-bottom: 1vh;
  }
`;

export const PlayerRankFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50%;
  padding-top: 1vh;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    padding-top: 1vh;
  }
`;

export const AvatarHUDViewerContainer = styled.div`
  position: relative;
  width: 10%;
  min-width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  shape-outside: circle();
  clip-path: circle();
  background: linear-gradient(#009fe3, #01ffff);
  z-index: 1;
  padding-right: 2vw;
  padding-left: 2vw;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    width: 30%;
  }
`;

export const InfoContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
`;

export const JokerContainer = styled.div`
  font-family: lilita;
  color: white;
  font-size: 1.5em;
  line-height: 1.2em;
  width: 45%;
  min-width: 45%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  white-space: nowrap;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 0.6em;
  }
`;

// NEW
export const ViewerContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  shape-outside: circle();
  clip-path: circle();
  background: #f4f4f4;
  z-index: 1;

  &.player-card-avatar {
    margin: 0 auto -2em auto;
  }
`;

export const PlayerDataContainer = styled.div`
  background: radial-gradient(circle at 50% -50%, #f4f4f428 48%, transparent 49%),
    linear-gradient(to bottom, #90dff5, #52acff);
  border-radius: 48px;
  padding: 2em 2em 1em 2em;
`;
