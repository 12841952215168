import styled from "styled-components";
import { theme } from "../../../theme";

export const BonusButtonsContainer = styled.div`
  display: flex;
  min-height: 3rem;
  align-items: center;
  width: 100%;
`;

export const JokerButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FiftyButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 2rem;
  width: 4rem;
  height: 4rem;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    height: 72px;
    width: 72px;
  }
`;

export const JokerButtonAssembly = styled.div`
  position: relative;
  display: flex;
  height: 4rem;
  width: 4rem;
  padding-right: 2rem;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    height: 72px;
    width: 72px;
  }
`;

export const LeftBonusRow = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 50%;
  justify-content: flex-end;
`;

export const RightBonusRow = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 50%;
  justify-content: flex-start;
`;
