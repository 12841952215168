import { AvatarAvailableParts } from "../../Avatar/models/avatar.model";
import { ApplicationConfig, Statistics, StatisticsSchema } from "../models/application.model";
import httpService from "../../../services/http.service";

export interface ApplicationServicesInterface {
  getApplicationConfig: (browserLanguage?: string) => Promise<ApplicationConfig>;
  getAvailableAvatars: () => Promise<AvatarAvailableParts>;
  getAvastarList: () => Promise<string[]>;
  getStatistics: () => Promise<Statistics>;
}

const getApplicationConfig = async (browserCurrentLanguage?: string): Promise<ApplicationConfig> => {
  const configHttpService = await httpService.get({
    auth: undefined,
    path: "/application/config",
    language: browserCurrentLanguage !== undefined && browserCurrentLanguage !== "" ? browserCurrentLanguage : "",
  });
  return {
    _id: configHttpService._id,
    applicationName: configHttpService.applicationName,
    branding: {
      primaryColor: configHttpService.branding.primaryColor,
      secondaryColor: configHttpService.branding.primaryColor,
      logo: configHttpService.branding.logo,
      logoLink: "https://fr.wikipedia.org/wiki/Fichier:Logo_Carrefour.svg",
      trailer: {
        type: configHttpService.branding.trailer.type,
        url: configHttpService.branding.trailer.url,
        poster: configHttpService.branding.trailer.poster,
      },
      gameRules: configHttpService.gameRules,
      legalPrivacyPolicy: configHttpService.legalPrivacyPolicy,
      startDate: configHttpService.branding.startDate ? new Date(configHttpService.branding.startDate) : undefined,
      stopDate: configHttpService.branding.stopDate ? new Date(configHttpService.branding.stopDate) : undefined,
    },
    footerLinkList: configHttpService.footerLinkList,
    userMetadataScheme: configHttpService.userMetadataScheme,
    jackpotAmount: configHttpService.jackpotAmount,
    defaultLanguage: configHttpService.defaultLanguage,
    languageList: configHttpService.languageList,
    cognitoConfig: configHttpService.cognitoConfig,
  };
};

const getAvailableAvatars = async (): Promise<AvatarAvailableParts> => {
  const avatarHttpService = await httpService.get({
    auth: undefined,
    path: "/application/avatarParts",
  });
  return {
    url: avatarHttpService.endpoint,
    parts: {
      hair: avatarHttpService.urls.hair,
      pencil: avatarHttpService.urls.pencil,
      body: avatarHttpService.urls.body,
      ears: avatarHttpService.urls.ears,
      eyes: avatarHttpService.urls.eyes,
      mouth: avatarHttpService.urls.mouth,
      nose: avatarHttpService.urls.nose,
      shape: avatarHttpService.urls.shape,
    },
  };
};

const getAvastarList = async (): Promise<string[]> => {
  const result = await httpService.get({
    auth: undefined,
    path: "/application/avastars",
  });
  return result.urls.map((url: string) => `${result.endpoint}/${url}`);
};

const getStatistics = async (): Promise<Statistics> => {
  const result = await httpService.get(
    {
      auth: true,
      path: "/application/stats",
    },
    StatisticsSchema,
  );

  return result;
};

const applicationService: ApplicationServicesInterface = {
  getApplicationConfig,
  getAvailableAvatars,
  getAvastarList,
  getStatistics,
};

export default applicationService;
