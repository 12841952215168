import styled from "styled-components";
import { theme } from "../../theme";

export const SpeechBubbleMainContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    width: 90%;
    min-width: 90%;
  }
`;

export const MainBubbleContainer = styled.div`
  padding: 1em;
  box-sizing: border-box;
  width: 30vw;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 1em;
  justify-content: center;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  @media (max-width: ${theme.notebook.breakPoint}) {
    min-width: 50vw;
  }
`;

export const SpeechBubbleRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1em 0 0.5em 0;
`;

export const DebriefTitlediv = styled.div`
  font-family: nunito;
  font-size: 1.8em;
  text-align: center;
  font-weight: bold;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 1.3em;
  }
`;

export const SpeechArrowRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: unsetc;
`;

export const SpeechBubbleArrow = styled.div`
  width: 10%;
  left: 80%;
  max-height: 50%;
  border-style: solid;
  border-width: 0 6em 6em 0;
  transform-origin: top;
  border-color: transparent #fff transparent transparent;
  transform: rotateY(180deg);
  margin-left: 10%;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 0.8em;
    color: white;
  }
  width: 10%;
  border-width: 0 3em 3em 0;
`;

export const PapagayoContainer = styled.div`
  position: relative;
  width: 5em;
  height: 5em;
  /* margin-left: 60%; */
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    margin: none;
    align-self: flex-start;
    width: 4em;
    height: 4em;
  }
`;

export const PapagayoMessage = styled.div`
  color: black;
  border-radius: 0.5em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1vh;
  padding-top: 1vh;
  width: 400px;
  align-items: center;
  margin: 0 auto;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 1em;
    width: 100%;
  }
`;

export const QuestionBolderText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  font-weight: bolder;
  font-size: 1.5em;
  margin: 1em 0 2em 0;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 1em;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 5em;
  padding: 1em;
  justify-content: space-evenly;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    height: 2em;
    padding: 1em;
  }
`;

export const LockDiv = styled.div`
  max-width: 70%;
  width: 70%;
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TreasureAndLockRowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 0.1vh;
  min-height: 5em;
`;
export const GoFinalRequestDiv = styled.div`
  font-family: lilita;
  font-weight: bold;
  color: #fff;
  font-size: 2.5em;
  margin: 0.5em 0 0.25em 0;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 2em;
    text-align: center;
  }
`;
