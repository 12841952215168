import React from "react";
import { CustomerLogoContainer, CustomerLogoDiv, GameLogoContainer, LogoAndCustomerBrandingRow } from "./Header.style";

interface Props {
  customerLogoUrl: string;
  centering: boolean;
}

const Header: React.FC<Props> = props => {
  return (
    <LogoAndCustomerBrandingRow centering={props.centering}>
      <GameLogoContainer centering={props.centering}></GameLogoContainer>
      <CustomerLogoContainer className={"customer-logo-container"}>
        <CustomerLogoDiv customerLogo={props.customerLogoUrl} />
      </CustomerLogoContainer>
    </LogoAndCustomerBrandingRow>
  );
};

export default Header;
