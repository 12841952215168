import React from "react";
import { LabelPlayerInfoStyle } from "./StandardHudPlayerLabelInfo.style";

interface Props {
  labelText: string;
}
const StandardPlayerLabelInfo: React.FC<Props> = props => {
  return <LabelPlayerInfoStyle>{props.labelText}</LabelPlayerInfoStyle>;
};

export default StandardPlayerLabelInfo;
