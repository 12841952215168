import React from "react";
import styled from "styled-components";
import { theme } from "../../theme";

const H1 = styled.h1`
  color: ${({ theme }) => theme.color.text};
  font-family: ${({ theme }) => theme.font.styledFont};
  font-size: 68px;
  text-transform: uppercase;

  &.secondary {
    color: ${({ theme }) => theme.color.textSecondary};
  }
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 30px;
  }
`;

interface Props {
  text: string;
}

const SectionTitle: React.FC<Props & Partial<HTMLTitleElement>> = props => {
  const { className, text } = props;

  return <H1 className={`section-title ${className ? className : ""}`}>{text}</H1>;
};

export default SectionTitle;
