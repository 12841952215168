import React, { useContext } from "react";

import LogicContext from "../../context/context";
import { QuestionTitleStyleFlexDiv, AdviceStyleFlexDiv, QuestionTextStyleFlexDiv } from "./QuestionLayout.style";

interface Props {
  questionTitle: string;
  questionNumber: number;
  questionAdviceText?: string;
}

const QuestionMainLayout: React.FC<Props> = props => {
  const { gameState } = useContext(LogicContext);

  return (
    <>
      <QuestionTitleStyleFlexDiv className="question-title">
        Question {props.questionNumber} /
        {gameState.store.game?.roundList[gameState.store.roundIndex].questionList.length}
      </QuestionTitleStyleFlexDiv>
      <QuestionTextStyleFlexDiv className="question-text"> {props.questionTitle} </QuestionTextStyleFlexDiv>
      {props.questionAdviceText && (
        <AdviceStyleFlexDiv className="question-advice-text">{props.questionAdviceText} </AdviceStyleFlexDiv>
      )}
    </>
  );
};

export default QuestionMainLayout;
