import Core from "./game.core";
import GameState from "../state";
import gameService from "../services/game.service";
import applicationState from "../../application/state";

const gameCore = Core({
  gameState: GameState,
  gameService,
  applicationState,
});

export default gameCore;
