import React from "react";

type Route = {
  view: React.LazyExoticComponent<React.FC<any>>;
  path: string;
};

//// HOME PRIVATE ROUTES ///////////

const PRIVATE_SUFFIX = "/auth";

export const GAME: Route = {
  view: React.lazy(() => import("../Ducks/game/views/Game.view")),
  path: `${PRIVATE_SUFFIX}/game`,
};

export const HOME: Route = {
  view: React.lazy(() => import("../Ducks/game/views/Home.view")),
  path: `${PRIVATE_SUFFIX}`,
};

export const UPDATEAVATAR: Route = {
  view: React.lazy(() => import("../Ducks/AvatarPicker/view/AvatarPicker.view")),
  path: `${PRIVATE_SUFFIX}/update-avatar`,
};

export const GAME_ROUTES = {
  UPDATEAVATAR,
  GAME,
  HOME,
};

export const ADMIN_SUFFIX = "/admin";

export const STATS: Route = {
  view: React.lazy(() => import("../Ducks/Admin/view/AdminStatistics.view")),
  path: `${ADMIN_SUFFIX}/statistics`,
};

export const ADMIN_ROUTES = {
  STATS,
};

//// PUBLIC ROUTES /////////////

const PUBLIC_SUFFIX = "/home";

export const AUTH: Route = {
  view: React.lazy(() => import("../Ducks/Auth/view/signup/SignUp.view")),
  path: `${PUBLIC_SUFFIX}/signup`,
};

// TODO: DEPRECATED with Cognito
export const LOGIN: Route = {
  view: React.lazy(() => import("../Ducks/Auth/view/login/Login.view")),
  path: `${PUBLIC_SUFFIX}/login`,
};

export const CONFIRM_ACCOUNT: Route = {
  view: React.lazy(() => import("../Ducks/Auth/view/ConfirmAccount/ConfirmAccount.view")),
  path: `${PUBLIC_SUFFIX}/account-confirmation`,
};

export const FORGOT_PWD: Route = {
  view: React.lazy(() => import("../Ducks/Auth/view/ForgotPassword/ForgotPassword.view")),
  path: `${PUBLIC_SUFFIX}/forgot-pwd`,
};

export const RESET_PWD: Route = {
  view: React.lazy(() => import("../Ducks/Auth/view/ResetPassword/ResetPassword.view")),
  path: `${PUBLIC_SUFFIX}/reset-pwd`,
};

export const AVATAR_CREATOR: Route = {
  view: React.lazy(() => import("../Ducks/Avatar/view/Avatar.view")),
  path: `${PUBLIC_SUFFIX}/avatar/new`,
};

export const AVATAR: Route = {
  view: React.lazy(() => import("../Ducks/AvatarPicker/view/AvatarPicker.view")),
  path: `${PUBLIC_SUFFIX}/avatar`,
};

export const LANDING: Route = {
  view: React.lazy(() => import("../Ducks/Landing/view/LandingPage.view")),
  path: `${PUBLIC_SUFFIX}/`,
};

export const BACK_DOOR_LOGIN: Route = {
  view: React.lazy(() => import("../Ducks/Auth/view/BackDoorLogin/BackDoorLogin.view")),
  path: `${PUBLIC_SUFFIX}/back-door-login`,
};

export const PUBLIC_ROUTES = {
  AVATAR,
  AVATAR_CREATOR,
  LOGIN,
  AUTH,
  LANDING,
  FORGOT_PWD,
  RESET_PWD,
  CONFIRM_ACCOUNT,
  BACK_DOOR_LOGIN,
};
