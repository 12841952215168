import React from "react";
import { FifthyFifthyDiv } from "./FifthyFifthyButton.style";
interface Props {
  useFifthyFifthy: () => void;
}

const FifthyFifthyButton: React.FC<Props> = props => {
  const useFifthyFifthy = () => {
    props.useFifthyFifthy();
  };
  return <FifthyFifthyDiv onClick={useFifthyFifthy} />;
};

export default FifthyFifthyButton;
