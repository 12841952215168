import styled from "styled-components";

interface LogoUpAndGo {
  imageUrl: string;
}

export const LogoUpAndGoDiv = styled.a<LogoUpAndGo>`
  background: ${props => `url(${props.imageUrl})`};
  background-origin: content-box;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;
