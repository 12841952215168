import React from "react";

import { PapagayoBirdStyle, PapagayoBec } from "./Papagayo.style";

interface Props {
  stopPapagayo: boolean;
}

const Papagayo: React.FC<Props> = props => {
  return (
    <>
      <PapagayoBirdStyle className={"papagayo-bird-style"}>
        <PapagayoBec stopAnimation={props.stopPapagayo} className={"papagayo-bec"} />
      </PapagayoBirdStyle>
    </>
  );
};

export default Papagayo;
