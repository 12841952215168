import styled from "styled-components";
import { theme } from "../../../theme";

interface StandardButtonInterface {
  secondaryColor: string;
}

export const StandardButtonStyle = styled.button<StandardButtonInterface>`
  user-select: none;
  z-index: 1200;
  font-size: 1.4em;
  font-weight: bolder;
  transition: 0.5s;
  border-radius: 2em;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 1em;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: none;
  white-space: nowrap;
  background-image: linear-gradient(to right, #f4af65 0%, #f19861 51%, #eb5e5c 100%);
  overflow: hidden;
  position: relative;
  user-select: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 1px 4px -2px #333;
  transition: transform 250ms cubic-bezier(0.25, 0.25, 0.5, 1.9);
  outline: none;
  &:hover {
    background-position: right center;
  }
  &:active {
    animation-fill-mode: forwards;
    transform: scale(0.9);
    outline: none;
    outline: 0;
    user-select: none;
  }
  &:focus {
    outline: 0;
    outline: none;
  }
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    border-radius: 2em;
    min-width: 50%;
    min-height: 100%;
    font-size: 1em;
    outline: none;
    padding: 1em;
  }
`;
