import React from "react";
import { LockBarRowContainer } from "./ProgressLockBar.style";
import { Lock } from "../../DumbComponents";
import { Round, Question } from "../../Ducks/game/models/game.model";

interface Props {
  questionIndex: number;
  openLockImage: string;
  closedLockImage: string;
  roundIndex: number;
  roundList: Round[];
  questionList: Question[];
}

const ProgressLockBar: React.FC<Props> = props => {
  console.log(props.openLockImage, props.closedLockImage);
  return (
    <LockBarRowContainer className={"lock-bar-row-container"}>
      {props.questionList !== undefined &&
        props.questionList.map((lock, index: number) => (
          <Lock
            key={index}
            imageUrl={
              props.questionIndex > 0 && index < props.questionIndex ? props.openLockImage : props.closedLockImage
            }
          />
        ))}
    </LockBarRowContainer>
  );
};

export default ProgressLockBar;
