import React from "react";
import { ValuePlayerInfoStyle } from "./StandardHudPlayerValueInfo.style";

interface Props {
  valueProps: number | string;
}
const StandardPlayerValueInfo: React.FC<Props> = props => {
  return <ValuePlayerInfoStyle>{props.valueProps}</ValuePlayerInfoStyle>;
};

export default StandardPlayerValueInfo;
