import { styled } from "../../theme";

export const DebugContainer = styled.div<{ debug: boolean }>`
  display: ${({ debug }) => (debug ? "flex" : "none")};
  font-family: "Poppins-Light" !important;
  font-weight: normal;
  flex-direction: row;
  align-items: center;
  position: absolute;
  z-index: 9;
  color: black;
  left: 2em;
`;
