import React, { useState } from "react";
import {
  ImageRowContainer,
  SlideRowTitle,
  MainSlideContainer,
  TextContainer,
  ButtonRowContainer,
  TitleDivStyle,
  ImageDivStyle,
  TextBlock,
  Arrow,
} from "./GameRulesSlider.style";
import SlideButton from "./SlideButton.component";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRounded from "@material-ui/icons/ArrowForwardIosRounded";

interface Props {
  title: string[];
  text: string[];
  image: string[];
  secondaryColor: string;
}

const GameRulesSlider: React.FC<Props> = props => {
  const [slideIndex, setSlideIndex] = useState<number>(0);

  const chooseSlideIndex = (slideIndexInput: number) => () => {
    setSlideIndex(slideIndexInput);
  };

  const createMarkup = () => {
    return { __html: props.text[slideIndex] };
  };

  return props.title !== undefined && props.text !== undefined && props.secondaryColor !== undefined ? (
    <MainSlideContainer>
      <SlideRowTitle className={"helper-title-container"}>
        <TitleDivStyle className={"helper-title"} color={props.secondaryColor}>
          {props.title[slideIndex]}
        </TitleDivStyle>
      </SlideRowTitle>

      <div className="rule-container" style={{ display: "flex", justifyContent: "center" }}>
        <Arrow
          className={"link"}
          style={{ justifyContent: "end", visibility: slideIndex !== 0 ? "visible" : "hidden" }}
          onClick={chooseSlideIndex(slideIndex - 1)}
        >
          <ArrowBackIosRoundedIcon />
        </Arrow>
        <div
          className={"helper-body-container"}
          style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "80%" }}
        >
          <ImageRowContainer indexTitle={slideIndex}>
            <ImageDivStyle imageUrl={props.image[slideIndex]} />
          </ImageRowContainer>
          <TextContainer indexTitle={slideIndex}>
            <TextBlock dangerouslySetInnerHTML={createMarkup()} />
          </TextContainer>
        </div>
        <Arrow
          style={{ visibility: slideIndex !== props.title.length - 1 ? "visible" : "hidden" }}
          className={"link"}
          onClick={chooseSlideIndex(slideIndex + 1)}
        >
          <ArrowForwardIosRounded />
        </Arrow>
      </div>
      <ButtonRowContainer  className={"helper-buttons-container"}>
        {props.title.map((title, index: number) => (
          <SlideButton key={index} selected={slideIndex === index} onClick={chooseSlideIndex(index)} />
        ))}
      </ButtonRowContainer>
    </MainSlideContainer>
  ) : (
    <div></div>
  );
};

export default GameRulesSlider;
