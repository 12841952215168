import { CircularProgress } from "@material-ui/core";
import React from "react";
import { SpinnerContainer } from "./Spinner.style";

const Spinner: React.FC<{}> = () => {
  return (
    <SpinnerContainer>
      <CircularProgress />
    </SpinnerContainer>
  );
};

export default Spinner;
