import styled from "styled-components";
import { theme } from "../../theme";

export const MainSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SlideRowTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
  max-width: 100%;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    min-height: 6vh;
  }
`;

interface Props {
  color: string;
}
export const TitleDivStyle = styled.div<Props>`
  font-size: 4em;
  color: orange;
  text-align: center;
  font-family: "lilita";

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 2em;
  }
`;

interface ImageContainerProps {
  indexTitle: number;
}
export const ImageRowContainer = styled.div<ImageContainerProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20vh;
`;

interface ImageProps {
  imageUrl: string;
}

export const ImageDivStyle = styled.div<ImageProps>`
  background: ${props => `url(${props.imageUrl})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-origin: content-box;
`;

export const TextContainer = styled.div<ImageContainerProps>`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 100%;
  font-size: 1.5em;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 0.8em;
  }
`;

export const TextBlock = styled.div`
  text-align: center;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
`;

export const ButtonRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 3em;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-evenly;
`;

interface SliderButtonProps {
  selected: boolean;
}

export const ButtonSlideStyle = styled.button<SliderButtonProps>`
  border-radius: 100%;
  background-color: ${props => (props.selected ? "gray" : "lightgray")};
  height: 16px;
  width: 16px;
  border: none;
  outline: none;
  box-shadow: none;

  :hover& {
    background-color: gray;
  }
  :focus& {
    background-color: gray;
  }

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    height: 12px;
    width: 12px;
  }
`;

export const Arrow = styled.a`
  display: flex;
  align-items: center;
  width: 10%;
  &.link {
    color: orange;
  }
`;
