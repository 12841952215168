import React, { CSSProperties, useEffect } from "react";
import { useScrollBodyLock } from "./ScrollLimiter";
import { CloseButton, ContentWindow, ModalContainer } from "./StandardModal.style";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";

interface Props {
  isModalOpen: boolean;
  onClickClose: () => void;
  customClass?: string;
  customStyle?: CSSProperties;
}

const StandardModal: React.FC<Props> = props => {
  const { lock, unlock } = useScrollBodyLock();

  useEffect(() => {
    props.isModalOpen ? lock() : unlock();
  }, [props.isModalOpen]);

  return props.isModalOpen ? (
    <ModalContainer className={`modal-full-container flex-center ${props.customClass ? props.customClass : ""}`}>
      <ContentWindow style={props.customStyle} className={"modal-content-window"}>
        <CloseButton onClick={props.onClickClose}>
          <HighlightOffRoundedIcon />
        </CloseButton>
        {props.children}
      </ContentWindow>
    </ModalContainer>
  ) : null;
};

export default StandardModal;
