import React, { useState } from "react";
import { GameScreen } from "../../Ducks/game/models/game.model";
import { PapagayoMessage, ImageContainer, AdviceTextDiv, TitleDivStyle } from "./BirdSpeech.style";
import { Image3D } from "../../styles/FX/3D/Rotation3dY.style";
import Typewriter from "../../styles/FX/Typewriter/Typewriter";
import CustomBirdSpeech from "./CustomBirdSpeech.component";

interface Props {
  secondaryColor?: string;
  adviceIntro?: string;
  debriefText: string;
  adviceEpilog?: string;
  triggerTypeWriter?: boolean;
  image3DUrlFirst?: string;
  image3DUrlSecond?: string;
  imageMode: boolean;
  currentGameScreen: GameScreen;
  sendEndAnimationStatus?: (stopAnimation: boolean) => void;
}

const BirdSpeech: React.FC<Props> = props => {
  const [stopPapagayo, powerOffPapagayo] = useState<boolean>(false);
  const stopAnimation = (command: boolean) => {
    powerOffPapagayo(command);
    props.sendEndAnimationStatus && props.sendEndAnimationStatus(command);
  };

  return (
    <CustomBirdSpeech customClass={props.currentGameScreen} stopPapagayo={stopPapagayo}>
      <PapagayoMessage
        className={"text-container"}
        gameScreenName={props.currentGameScreen ? props.currentGameScreen : ""}
      >
        {props.adviceIntro !== undefined && <TitleDivStyle>{props.adviceIntro}</TitleDivStyle>}
        <Typewriter stopAnimation={stopAnimation} sourceText={props.debriefText} textClass={"bold"} />
      </PapagayoMessage>
      {stopPapagayo && props.imageMode && (
        <>
          <ImageContainer className={"bird-head-container"}>
            {props.image3DUrlFirst && <Image3D className={"img3D-first"} url={props.image3DUrlFirst} />}
            {props.image3DUrlSecond && <Image3D className={"img3D-second"} url={props.image3DUrlSecond} />}
          </ImageContainer>
          {props.adviceEpilog && <AdviceTextDiv className={"advice-epilog"}>{props.adviceEpilog}</AdviceTextDiv>}
        </>
      )}
    </CustomBirdSpeech>
  );
};

export default BirdSpeech;
