import { observable, computed } from "mobx";
import { ApplicationConfig } from "../models/application.model";
import { AvatarAvailableParts, Avatar } from "../../Avatar/models/avatar.model";

export default class Store {
  @observable
  avatarPartsList?: AvatarAvailableParts;

  @observable
  avastarList: string[] = [];

  @observable
  savedAvatar: Avatar = "https://bp-plateforme.s3.eu-west-1.amazonaws.com/avastars/avastars-2020_jeanclaudedusse.png";

  @observable
  config?: ApplicationConfig;

  @computed
  get isGameOpened(): boolean | null {
    if (!this.config) return null;

    const { startDate } = this.config.branding;

    if (!startDate) return true;

    return new Date(Date.now()) >= startDate;
  }

  @computed
  get isGameClosed(): boolean | null {
    if (!this.config) return null;

    const { stopDate } = this.config.branding;

    if (!stopDate) return true;

    return new Date(Date.now()) >= stopDate;
  }

  @observable
  browserLanguage?: string;

  @observable
  defaultLanguage?: string;

  @observable
  languageList?: string[];

  @observable
  isMuted = false;
}
