import styled from "styled-components";
import { theme } from "../../theme";

interface LockProps {
  lockImage: string;
}

export const LockDiv = styled.div<LockProps>`
  width: 30px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${props => `url(${props.lockImage})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  color: white;
  padding: 1em;
  margin: 2em 0.5em;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    width: 0.5vw;
    height: 50%;
  }
`;
