import { styled, theme } from "../../theme";
import StandardText from "../UI/StandardText";

export const CardContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  overflow: hidden;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    flex-direction: column;
  }
`;

export const Col = styled.div`
  padding: 2em;
  position: relative;
  overflow: hidden;

  &.super-bonus-card {
    min-width: 300px;
    z-index: 1;
  }

  &.super-bonus-card:before {
    content: "";
    position: absolute;
    width: 800px;
    height: 800px;
    border-radius: 800px;
    z-index: -1;
    box-shadow: 0 8px 31px 0 rgba(0, 0, 0, 0.15);
    transform: translate(250px, 0);
  }

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    &.game-description-container {
      text-align: center;
    }

    &.super-bonus-card {
      padding: 0;
      box-shadow: none;
      width: 100%;
      border-radius: 0;
      z-index: 1;
    }

    &.super-bonus-card:before {
      content: "";
      position: absolute;
      top: 0;
      width: 350px;
      height: 350px;
      border-radius: 350px;
      z-index: -1;
      box-shadow: 0 8px 31px 0 rgba(0, 0, 0, 0.15);
      transform: translateY(30px);
    }
  }
`;

export const StyledText = styled(StandardText)`
  color: ${({ theme }) => theme.color.textStyled};

  &.bonus-title {
    font-family: ${({ theme }) => theme.font.styledFont};
    font-size: 1.8em;
    font-weight: bolder;
    text-transform: uppercase;
  }
`;
