import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  border-radius: 25px;
`;

export const FirstRowContainerJokerImage = styled.div`
  display: flex;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

export const SecondRowContainerText = styled.div`
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const ThirdRowContainerButton = styled.div`
  display: flex;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const FourthRowContainerLink = styled.div`
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
