import styled from "styled-components";

export const LoaderLayout = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff99;
  z-index: 9999;
`;
