import styled from "styled-components";
import { theme } from "../../theme";

interface DataInfoValue {
  color: string;
}

export const DataInfoValueStyleDiv = styled.div<DataInfoValue>`
  font-size: 1em;
  font-weight: bolder;
  color: ${props => (props.color === "white" ? "white" : "black")};
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 0.8em;
  }
`;
