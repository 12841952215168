import styled from "styled-components";

export const LinkButtonDiv = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  color: black;
  height: 5em;
  border-radius: 0.5em;
  text-decoration: underline;
`;
