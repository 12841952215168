import { keyframes } from "styled-components";

export const Rotation3dYStart = "transform: rotatey(0deg);";
export const Rotation3dYEnd = "transform: rotateY(360deg);";

export const Rotation3dY = keyframes`
  0% {
    ${Rotation3dYStart}
  } 
  100% {
    ${Rotation3dYEnd}
  }
`;
