import Store from "./game.store";
import Mutator from "./game.mutator";

const store = new Store();
const mutator = new Mutator({ gameStore: store });

export interface GameStateInterface {
  store: Store;
  mutator: Mutator;
}

export default {
  store,
  mutator,
};
