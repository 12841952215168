import styled from "styled-components";

export const AbandonpButtonDiv = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  height: 5em;
  font-size: 1.3em;
  font-weight: bold;
  background: none;
  text-decoration: underline;
  margin-left: 2em;
`;
