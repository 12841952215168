import React from "react";
import { ButtonSlideStyle } from "./GameRulesSlider.style";

interface Props {
  selected: boolean;
  onClick: () => void;
}

const SlideButton: React.FC<Props> = props => {
  return <ButtonSlideStyle selected={props.selected} onClick={props.onClick} />;
};

export default SlideButton;
