import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

export const Col = styled.div`
  &.illustration-col {
    min-width: 60px;
    width: 25%;
  }
`;
