import { computed, observable } from "mobx";
import { Game, AnswerResult, FiftyResult, GameScreen, Question } from "../models/game.model";

export default class Store {
  @observable
  game?: Game;

  @observable
  roundIndex = 0;

  @observable
  questionIndex = 0;

  @observable
  jokerAmount = 0;

  @observable
  idOfLastAnswerChoosen?: string;

  @observable
  currentGameAnswerResultList: (AnswerResult | FiftyResult)[] = [];

  @observable
  currentGameScreen: GameScreen = "home";

  @computed
  get currentQuestion(): Question | undefined {
    return this.game?.roundList[this.roundIndex].questionList[this.questionIndex];
  }
}
