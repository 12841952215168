import { styled, theme } from "../../../theme";

const AVATAR_WIDTH_IN_PX = 170;

export const AvatarHUDViewerContainer = styled.div`
  position: relative;
  height: ${`${AVATAR_WIDTH_IN_PX}px`};
  width: ${`${AVATAR_WIDTH_IN_PX}px`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  shape-outside: circle();
  clip-path: circle();
  background: #f4f4f4;
  z-index: 1;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    width: 30%;
    height: ${`${AVATAR_WIDTH_IN_PX - 50}px`};
  }
`;

export const HudContainer = styled.div`
  background-image: linear-gradient(to bottom, #90dff5, #52acff);
  display: flex;
  align-items: center;
  border-radius: 150px;
  height: 8em;
  margin: 3em;
  justify-content: space-between;
  width: 50em;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    margin: 0;
    justify-content: center;
    width: 100%;
    height: 6em;
    border-radius: unset;
  }
`;

export const HudCentralContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    justify-content: space-around;
    width: 100%;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;

  &.left {
    text-align: right;
  }

  &.right {
    text-align: left;
  }
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    font-size: 1.1em;
    height: 50px;
    width: 80px;
  }
`;

export const Badge = styled.img`
  z-index: 2;
  width: 190px;
  height: 12em;
  width: 11em;
  position: relative;
  &.left {
    right: 3.5em;
  }

  &.right {
    left: 3.5em;
  }
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    display: none;
  }
`;

export const NoBadge = styled.div`
  width: 11em;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    display: none;
  }
`;

export const JokerButtonAssembly = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  height: 4rem;
  width: 4rem;
  @media (max-width: ${theme.mobileFirst.breakPoint}) {
    width: 45px;
    height: 45px;
  }
`;
