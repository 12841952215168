import styled from "styled-components";
import { theme } from "../../../../theme";

export const AssetShapeImage = styled.img`
  width: 70%;
  object-fit: contain;
  :focus & {
    outline: 0;
  }
  :active & {
    outline: "none";
  }
  user-select: none;
  -webkit-user-drag: none;
  pointer-events: none;
`;

export const CharacterBodyPartsContainer = styled.div`
  position: absolute;
  bottom: 8%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

interface CharacterInterfaceProps {
  active?: boolean;
  heigthProps?: string;
  miniatureAvatar?: boolean;
}

export const MainCharacterContainer = styled.div<CharacterInterfaceProps>`
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media (max-width: ${theme.mobileFirst.breakPoint}) {
  }
`;
