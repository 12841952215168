import styled from "styled-components";

interface Props {
  backGroundUrl: string;
}
export const GeometricBackGroundStyle = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  min-width: 100%;
  align-self: center;
  min-height: 100vh;
  background-image: ${props => `url(${props.backGroundUrl})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0.3;
`;
