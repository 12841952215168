import styled from "styled-components";

export const Col = styled.div<{ widthInPercents: number }>`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: ${({ widthInPercents }) => `${widthInPercents}%`};
`;
